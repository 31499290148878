<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "OpenDuePayment",
  computed: {
    contract() {
      return this.$store.state.contract.contract;
    },
  },
  watch:{
    contract(c){
        if(c && c.due > 0){
          this.checkPayment();
        }
    }
  },
  methods:{
    payment(){
      this.paymentMode = true;
    },
    createPayment(){
        let url = "myLogin/payOpenDue";

        this.axios.post(url, this.formData).then(res => {
            this.paymentData= res.data;
            setTimeout(()=>{
              this.$refs.payment.submit();
            },200);
        });
    },
    checkPayment(){
      let url = "myLogin/checkPayment";

      this.axios.post(url).then(res => {
        this.paymentInProcess = res.data.check;

        if(this.paymentInProcess){
          setTimeout(()=>{
            this.checkPayment();
          },5000);
        }
      });
    }
  },
  data(){
    return {
      paymentInProcess: true,
      paymentMode: false,
      form: {},
      paymentData: null,
      formData: {
        paymentMethod: null,
      }
    }
  }
})
</script>

<template>
  <div id="openDue" v-if="contract && contract.due > 0">
    <form ref="payment" v-if="paymentData" :action="paymentData.url" method="post">
      <div class="form-group" v-for="(value, key) in paymentData.data" :key="value">
        <input type="hidden"  v-model="paymentData.data[key]" :name="key" >
      </div>
    </form>
    <div class="widget active mb-3" v-if="paymentMode">
      <div class="inner">
        <div class="hr mt-5">
          <span>Zahlungsmittel</span>
        </div>
        <ul class="dataListing" style="max-width: 650px; margin: auto;">
          <li  style="overflow: auto;">
            <ul class="payment">
              <li><input name="payment" id="paypal" value="paypal" type="radio" v-model="formData.paymentMethod"><label  for="paypal"><img src="@/assets/paypal_logo.png" srcset="@/assets/paypal_logo_2x.png 2x"><span></span></label></li>
              <li><input name="payment" id="visa" value="visa" type="radio" v-model="formData.paymentMethod"><label  for="visa"><img src="@/assets/visa.png" srcset="@/assets/visa_2x.png 2x"> <span></span></label></li>
              <li><input name="payment" id="mastercard" value="mastercard" type="radio" v-model="formData.paymentMethod"><label for="mastercard"><img src="@/assets/mastercard.png" srcset="@/assets/mastercard_2x.png 2x"> <span></span></label></li>
              <li><input name="payment" id="postfinance" value="postfinancecard" type="radio" v-model="formData.paymentMethod"><label  for="postfinance"><img src="@/assets/postfinance_card_chf.png" srcset="@/assets/postfinance_card_chf_2x.png 2x"> <span></span></label></li>
            </ul>
          </li>
        </ul>
        <div class="mt-3 text-center">
          <button class="btn btn-primary " @click="createPayment()">Jetzt bezahlen</button>
          <div class="menu text-center mb-3">
            <span @click="paymentMode=false">Abbrechen</span>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-warning  text-center">
      Du hast offene Forderungen von <strong>CHF {{ $filters.formatCurrency(contract.due)}}</strong>. Du kannst Sie jetzt hier direkt bezahlen.

      <div class="mt-3">
        <button class="btn btn-primary" @click="payment()" :disabled="paymentInProcess">Jetzt bezahlen</button>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>